import { Button } from '@mui/material'
import React from 'react'
import InputAdmin from '../../items/inputs/Input'
import FirebaseService from 'src/services/firebase.service';

const FirebaseTab = () => {
    const firebaseService = new FirebaseService();
    
    return (
        <div className='flex flex-col items-center'>
            <div className='m-2 text-lg text-[#7c7c7c]'>Fill all Prices</div>
            <Button variant="contained" color='primary' onClick={firebaseService.uploadPricesToFireStore}>Fill</Button>

            <div className='m-2 text-lg text-[#7c7c7c]'>Remove all Prices</div>
            <Button variant="contained" color='error' onClick={firebaseService.removeAllPricesFromFireStore}>Remove All</Button>

            <div className='m-2 text-lg text-[#7c7c7c]'>Enter query</div>
            <InputAdmin />

        </div>
    )
}   

export default FirebaseTab