import React from 'react'
import './uistyle.css'

interface ITvoiInputProps {
    placeholder: string;
    value: any;
    onChange: (value: any) => void;
}

const TvoiInput = (props: ITvoiInputProps) => {
    return (
        <input
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            placeholder={props.placeholder}
            className="tvoi-input"
        />
    )
}

export default TvoiInput;