import { commandErrors, commandRegex, operators } from "src/components/Admin/constants/firebase/attributes";
import FirebaseService from "./firebase.service";
import { collection, deleteDoc, doc, DocumentData, getDocs, query, Query, setDoc, where } from "firebase/firestore";
import { db } from '../configs/firebase';

class FirebaseQueryService extends FirebaseService {
    constructor() {
        super();
    }

    public async convertQueryToFirestoreCommand(command: string) {
        try {
            const lowerCaseQuery = command.toLowerCase().trim();
    
            if (commandRegex.SELECT_REGEX.test(lowerCaseQuery)) {
                return await this.handleSelectQuery(command);
            }
    
            if (commandRegex.UPDATE_REGEX.test(lowerCaseQuery)) {
                return await this.handleUpdateQuery(command);
            }
    
            if (commandRegex.DELETE_REGEX.test(lowerCaseQuery)) {
                return await this.handleDeleteQuery(command);
            }
    
            throw new Error(commandErrors.INVALID_QUERY);
        } catch (error) {
            console.error("Error converting query:", error);
        }
    }

    private async handleSelectQuery(command: string) {
        const match = command.match(commandRegex.SELECT_REGEX);
        if (!match) throw new Error(commandErrors.INVALID_QUERY);
    
        const collectionName = match[1];
        const conditions = match[2];
    
        const firestoreConditions = this.parseConditions(conditions);
    
        let firestoreQuery: Query<DocumentData> = collection(db, collectionName);
        firestoreConditions.forEach(({ field, operator, value }) => {
            firestoreQuery = query(firestoreQuery, where(field, operator as any, value));
        });
    
        const querySnapshot = await getDocs(firestoreQuery);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
    
    private async handleUpdateQuery(command: string) {
        const match = command.match(commandRegex.UPDATE_REGEX);
        if (!match) throw new Error(commandErrors.INVALID_UPDATE);
    
        const collectionName = match[1];
        const updates = match[2];
        const conditions = match[3];
    
        const firestoreConditions = this.parseConditions(conditions);
        const updatesObject = this.parseUpdateFields(updates);
    
        let firestoreQuery: Query<DocumentData> = collection(db, collectionName);
        firestoreConditions.forEach(({ field, operator, value }) => {
            firestoreQuery = query(firestoreQuery, where(field, operator as any, value));
        });
    
        const querySnapshot = await getDocs(firestoreQuery);
    
        const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
            const docRef = doc(db, collectionName, docSnapshot.id);
            await setDoc(docRef, updatesObject, { merge: true });
        });
    
        await Promise.all(updatePromises);
        return `Updated ${updatePromises.length} documents successfully.`;
    }
    
    private async handleDeleteQuery(command: string) {
        const match = command.match(commandRegex.DELETE_REGEX);
        if (!match) throw new Error(commandErrors.INVALID_DELETE);
    
        const collectionName = match[1];
        const conditions = match[2];
    
        const firestoreConditions = this.parseConditions(conditions);
    
        let firestoreQuery: Query<DocumentData> = collection(db, collectionName);
        firestoreConditions.forEach(({ field, operator, value }) => {
            firestoreQuery = query(firestoreQuery, where(field, operator as any, value));
        });
    
        const querySnapshot = await getDocs(firestoreQuery);
    
        const deletePromises = querySnapshot.docs.map(async (docSnapshot) => {
            const docRef = doc(db, collectionName, docSnapshot.id);
            await deleteDoc(docRef);
        });
    
        await Promise.all(deletePromises);
        return `Deleted ${deletePromises.length} documents successfully.`;
    }
    
    private parseConditions(conditions: string) {
        return conditions.split(commandRegex.CONDITION_REGEX).map((condition) => {
            const operator = operators.find(op => condition.includes(op));
            if (!operator) throw new Error(commandErrors.INVALID_CONDITION);
    
            const [field, value] = condition.split(operator).map(part => part.trim());
            const sanitizedValue = value.startsWith('"') && value.endsWith('"')
                ? value.slice(1, -1)
                : isNaN(Number(value)) ? value : Number(value);
    
            return { field, operator, value: sanitizedValue };
        });
    }
    
    private parseUpdateFields(updates: string) {
        return updates.split(',').reduce((acc, update) => {
            const [field, value] = update.split('=').map(part => part.trim());
            const sanitizedValue = value.startsWith('"') && value.endsWith('"')
                ? value.slice(1, -1)
                : isNaN(Number(value)) ? value : Number(value);
            acc[field] = sanitizedValue;
            return acc;
        }, {} as Record<string, any>);
    }

}

export default FirebaseQueryService;