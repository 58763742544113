import React from 'react'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { translateText } from 'src/services/translation.service';
import { DefaultModal } from 'src/components/windows/DefaultModal';
import AddYourProductWindow from 'src/components/windows/AddYourProductWindow';
import useWindowSize from 'src/hooks/useWindowSize.hook';


const AdProduct = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let size = useWindowSize();
    const isMobile = size.width < 770;
    
    const _renderAdProduct = () => {
        return(
            <DefaultModal open={open} handleClose={handleClose} handleOpen={handleOpen}>
                <AddYourProductWindow title='ad_product|A' textAction='ad_product_text|A'/>
            </DefaultModal>
        )
    }

    return (
        <div className='productCard'>
            <div className='productImagePos bg-[#f2f2f2] rounded-[20px] border-dashed border-2 border-blue-400' onClick={handleOpen}>
                <div className='flex flex-col items-center gap-2'>
                    <div>
                        <AddAPhotoIcon 
                            style={{
                                color: '#93c5fd',
                                fontSize: isMobile ? '3rem' : '5rem'
                            }}
                        />
                    </div>
                    <div className='text-base text-blue-400'>
                        {translateText('ad_promo|A')}
                    </div>
                </div>
            </div>
            {open && _renderAdProduct()}
        </div>
        
    )
}

export default AdProduct