import React, { createContext, useState, useContext, ReactNode } from "react";
import { useGetDataFromFirestore } from "src/hooks/useGetDataFromFirestore.hook";

type BannerContextType = {
    banners: IBanner[];
};

const BannerContext = createContext<BannerContextType | undefined>(undefined);

export const BannerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [banners, setBanners] = useState<IBanner[]>([]);

    const { data: bannersData } = useGetDataFromFirestore('banners');
    
    React.useEffect(() => {
        if (bannersData.length > 0) {
            setBanners(bannersData);
        }
    }, [bannersData]);

    return (
        <BannerContext.Provider value={{ banners }}>
            {children}
        </BannerContext.Provider>
    );
};

export const useBanner = (): BannerContextType => {
    const context = useContext(BannerContext);
    if (!context) {
        throw new Error("useBanner must be used within a BannerProvider");
    }
    return context;
};
