import React, { useEffect, useState } from 'react';
import { collection, getDocs, getDoc, doc} from "firebase/firestore";
import { db } from '../configs/firebase';
import { ProductContext } from 'src/context/product-context';

export function useGetDataFromFirestore(collectionName: string) {
    const [data, setData] = useState<any[]>([]);
    const { setIsLoading } = React.useContext(ProductContext);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const querySnapshot = await getDocs(collection(db, collectionName));
            const newData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setData(newData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return { data, fetchData };
}

export function useGetDataFromFireStoreById(collectionName: string, id: string) {
    const [data, setData] = useState<any | null>(null);

    const fetchData = async () => {
        try {
            const docRef = doc(db, collectionName, id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setData({ ...docSnap.data(), id: docSnap.id });
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return { data, fetchData };
}