import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CarouselGallery } from "../components/productsView/Carousel";
import { FooterPanel } from "../components/FooterPanel";
import { translateText } from "../services/translation.service";
import { getRoute } from "../services/routes.service";
import { Helmet } from "react-helmet";
import CardProducts from "../components/productsView/CardProduct";
import Categories from "../components/productsView/Categories";
import { ProductContext } from "../context/product-context";
import useWindowSize from "../hooks/useWindowSize.hook";
import SearchField from "../components/SearchField";
import WomanDay from "../components/eventboards/WomanDay";
import Spinner from "../components/loading/Spinner";
import ReminderBanner from "../components/eventboards/ReminderBanner";
import GlassesCollections from "src/components/eventboards/GlassesCollections";
import ForSheAndHeBanner from "src/components/eventboards/ForSheAndHeBanner";
import GoogleAutoAd from "src/adsense/GoogleAutoAd";
import Tips from "src/components/Tips";
import BlackFriday from "src/components/eventboards/BlackFriday";
import Christmas from "src/components/eventboards/Christmas";
import { BannerTypes } from "src/constants/enums/banner.enum";
import { useGetDataFromFireStoreById } from "src/hooks/useGetDataFromFirestore.hook";
import DefaultBanner from "src/components/banners/default-banner";

const WALLPAPER_DEFAULT = 'https://live.staticflickr.com/65535/52644560059_1f10d3f810_h.jpg';

const Home = () => {
  const { products } = useContext(ProductContext);
  const [popularProducts, setPopularProducts] = React.useState<IProduct[]>([]);
  const [offeredProduct, setOfferedProduct] = React.useState<IProduct[]>([]);

  const {data: christmasBannerData} = useGetDataFromFireStoreById('banners', BannerTypes.CHRISTMAS);
  const {data: sheAndHerBannerData} = useGetDataFromFireStoreById('banners', BannerTypes.SHE_AND_HER);

  let size = useWindowSize();
  const isMobile = size.width < 620;

  useEffect(() => {
    const popularItems = [...products].sort((a, b) => a.popularity - b.popularity);
    const offeredItems = [...products].sort(() => Math.random() - 0.5);
    setOfferedProduct(offeredItems.splice(0, 101));
    setPopularProducts(popularItems.splice(0, 10));
  }, [products]);

  const _renderSeoHeader = () => {
    return(
      <Helmet>
        <title>
          TVOI | Home - Срібні брендові прикраси за низькими цінами купити у Львові у Києві
        </title>
      </Helmet>
    )
  }

  const _renderSearchField = () => {
    return(
      <div className="px-3 pt-2">
        <SearchField/>
      </div>
    )
  }

  const _renderWebBanner = () => {
    return(
      <div className="relative flex justify-center items-center w-full h-[500px] bg-[#f2f2f2]">
        <img
          className="home-banner"
          src={WALLPAPER_DEFAULT}
        />
        <div className="home-banner-text">
          <SearchField />
        </div>
      </div>
    )
  }

  const _renderSecondaryBanner = () => {
    return(
      <div>
        {!isMobile && <div className="header_label">Знижки</div>}
        <WomanDay />
        {/*<ReminderBanner date='08.03'/>*/}
      </div>
    )
  }

  const _renderCategories = () => {
    return(
      <div>
        <div className="header_label">
          {translateText("category|A")}
        </div>
        <Categories />
      </div>
    )
  }

  const _renderCarousel = () => {
    return(
      <div>
        <div className="header_label">
          {translateText("often bought|A")}
        </div>
        <CarouselGallery products={popularProducts} />
      </div>
    )
  }

  const _renderOfferedProducts = () => {
    return(
      <div>
        <div className="header_label">
          {translateText("more to love|A")}
        </div>
        <CardProducts products={offeredProduct} style="productView" />
      </div>
    )
  }

  const _renderTips = () => {
    return (
      <div className="mx-3">
        <Tips/>
      </div>
    )
  }

  const _renderData = () => {
    const [isMobile, setIsMobile] = React.useState(false);

    React.useLayoutEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
      <div className="flex flex-col gap-3 sm:mt-[50px] mt-[60px] mb-[15px]">
        {_renderSeoHeader()}
        {!isMobile && _renderWebBanner()}
        {isMobile && (
          <>
            {_renderTips()}
            {_renderSearchField()}
          </>
        )}
        {_renderCategories()}
        <DefaultBanner
          id={christmasBannerData?.id}
          url={christmasBannerData?.url}
          text={christmasBannerData?.text}
          subText={christmasBannerData?.subText}
          action={christmasBannerData?.action}
          actionText={christmasBannerData?.actionText}
        />
        {_renderCarousel()}
        <GoogleAutoAd marginLeft="15px" marginRight="15px"/>
        <DefaultBanner
          id={sheAndHerBannerData?.id}
          url={sheAndHerBannerData?.url}
          text={sheAndHerBannerData?.text}
          subText={sheAndHerBannerData?.subText}
          action={sheAndHerBannerData?.action}
          actionText={sheAndHerBannerData?.actionText}
          right={true}
        />
        {_renderOfferedProducts()}
        <GoogleAutoAd marginLeft="15px" marginRight="15px"/>
        <FooterPanel />
      </div>
    )
  }

  const _renderSpinner = () => {
    return(
      <div style={{
        height: 'calc(100vh - 50px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Spinner/>
      </div>
    )
  }

  return products ? _renderData() : _renderSpinner();
};

export default Home;
