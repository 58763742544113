import React from 'react'
import { useNavigate } from 'react-router';
import { getRoute } from 'src/services/routes.service';
import { translateText } from 'src/services/translation.service';
import { buttonStyles, containerStyles, editIconStyles, subTextStyles, textStyles } from './default-banner.styles';
import { UserContext } from 'src/context/user-context';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { DefaultModal } from '../windows/DefaultModal';
import EditBannerWindow from '../windows/EditBannerWindow';

interface IDefaultBannerProps extends IBanner {
    shadow?: boolean;
    right?: boolean;
}

const DefaultBanner = (props: IDefaultBannerProps) => {
    const { authorized } = React.useContext(UserContext);
    const [editWindow, setEditWindow] = React.useState(false);
    const navigate = useNavigate();
    
    const action = () => {
        if (props.action) {
            navigate(getRoute(props.action));
        }
    }

    const imageStyles: string = `w-full rounded-[45px] sm:scale-75 ${props.shadow && 'shadow'}`;

    const renderActionButton = () => {
        return(
            <div className='flex justify-center items-center'>
                <button onClick={action} className={buttonStyles} style={{letterSpacing: '1px'}}>{translateText(props.actionText!)}</button>
            </div>
        )
    }

    const editIcon = () => {
        return(
            <div onClick={() => setEditWindow(true)}>
                <EditNoteIcon style={{cursor: 'pointer'}}/>
            </div>
        )
    }

    const renderEditWindow = () => {
        return(
            <DefaultModal open={editWindow} handleClose={() => setEditWindow(false)} handleOpen={() => setEditWindow(true)}>
                <EditBannerWindow 
                    id={props.id} 
                    url={props.url}
                    text={props.text}
                    subText={props.subText}
                    action={props.action}
                    actionText={props.actionText}
                    handleClose={() => setEditWindow(false)}
                />
            </DefaultModal>
        )
    }

    return (
        <div className={containerStyles} style={{ backgroundColor: 'white' }}>
            <div className={editIconStyles}>
                {authorized && editIcon()}
            </div>
            {!props.right && <img src={props.url} alt="model" className={imageStyles}/>}
            <div className='w-full flex justify-center items-center'>
                <div className='lg:text-[75px] text-[45px] font-[900] text-center py-5 pt-5 pb-1' style={{letterSpacing: -2.5}}>
                    <div className={textStyles}>{translateText(props.text)}.</div>
                    <div className={subTextStyles} style={{letterSpacing: -0.7}}>{translateText(props.subText!)}</div>
                    {renderActionButton()}
                </div>
            </div>
            {props.right && <img src={props.url} alt="model" className={imageStyles}/>}
            {editWindow && renderEditWindow()}
        </div>

    )
}

export default DefaultBanner;
