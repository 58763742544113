import React, { useEffect, useState } from 'react';
import TvoiInput from '../ui/tvoi-input';
import TvoiButton from '../ui/tvoi-button';
import { useGetDataFromFireStoreById } from 'src/hooks/useGetDataFromFirestore.hook';
import FirebaseService from 'src/services/firebase.service';

interface IEditBannerWindowProps extends IBanner {
    handleClose: () => void;
}

const firebaseService = new FirebaseService();

const EditBannerWindow: React.FC<IEditBannerWindowProps> = (props: IEditBannerWindowProps) => {
    const [bannerData, setBannerData] = useState<IBanner>({
        url: props.url,
        text: props.text,
        subText: props.subText,
        action: props.action,
        actionText: props.actionText,
    });
    const [loading, setLoading] = useState(true);
    const [updatedBannerData, setUpdatedBannerData] = useState(bannerData);
    const { data: banner, fetchData } = useGetDataFromFireStoreById('banners', props.id ?? '');

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (banner) {
            setBannerData(banner);
            setUpdatedBannerData(banner);
            setLoading(false);
        }
    }, [banner, props.id]);

    const handleInputChange = (field: string, value: string) => {
        setUpdatedBannerData({
            ...updatedBannerData,
            [field]: String(value),
        });
    };

    const handleSaveChanges = async () => {
        try {
            const update = await firebaseService.updateDataInFireStore('banners', props.id ?? '', updatedBannerData);
            await update.updateData();
            console.log('Document successfully updated!');
            props.handleClose();
            window.location.reload();
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const inputFields = [
        { label: 'Image Url', field: 'url', placeholder: 'Url' },
        { label: 'Text', field: 'text', placeholder: 'Text' },
        { label: 'Sub Text', field: 'subText', placeholder: 'Sub Text' },
        { label: 'Action', field: 'action', placeholder: 'Action' },
        { label: 'Action Text', field: 'actionText', placeholder: 'Action Text' },
    ];

    return (
        <div className='flex flex-col gap-3 items-center h-[500px] w-full'>
            <div className='text-xl font-bold text-[#414141] text-center'>Edit this Banner:</div>

            <div className='overflow-y-auto w-full'>
                {inputFields.map(({ label, field, placeholder }) => (
                    <div key={field} className='flex flex-col gap-1'>
                        <div className='text-sm text-left text-[#6a6a6a]'>{label}</div>
                        <TvoiInput
                            placeholder={placeholder}
                            value={updatedBannerData[field as keyof typeof updatedBannerData]}
                            onChange={(value: any) => {handleInputChange(field, value)}}
                        />
                    </div>
                ))}
            </div>

            <TvoiButton
                textColor='#fff'
                color='#111111'
                placeholder="Save Changes"
                onClick={handleSaveChanges}
            />
        </div>
    );
};

export default EditBannerWindow;
