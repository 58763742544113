import React from 'react'
import './uistyle.css'

interface ITvoiButtonProps {
    placeholder: string;
    onClick: () => void;
    color?: string;
    textColor?: string; 
}

const TvoiButton = (props: ITvoiButtonProps) => {
    return (
        <button
            style={{backgroundColor: props.color, color: props.textColor}}
            className='tvoi-button'
            onClick={props.onClick}
        >
            {props.placeholder}
        </button>
    )
}

export default TvoiButton;